<template>
  <div>
    <Modal v-model="show" width="800">
      <p slot="header" class="text-center">{{ curInfo.name }}</p>
      <div>
        <!-- <h4 class="workplatform-title m-t-10 m-b-10">租户基本信息</h4> -->
        <Row class="p-b-10">
          <i-col span="8">
            <span class="title">社会统一信用代码</span>{{ curInfo.orgCode }}
          </i-col>
          <i-col span="8">
            <span class="title">租户类型</span>{{ curInfo.typeName }}
          </i-col>
          <i-col span="8">
            <span class="title">法人代表</span>{{ curInfo.legalPerson }}
          </i-col>
        </Row>
        <Row class="p-b-10">
          <i-col span="8">
            <span class="title">租户状态</span>{{ curInfo.companyAdvertiserStatusName }}
          </i-col>
          <i-col span="8">
            <span class="title">创建时间</span>{{ curInfo.createTime }}
          </i-col>
        </Row>
        <Row class="p-b-10">
          <i-col span="24">
            <span class="title">详细地址</span>{{ curInfo.provinceName + curInfo.cityName + curInfo.areaName + curInfo.address }}
          </i-col>
        </Row>
        <Row class="p-b-10">
          <i-col span="24">
            <span class="title">备注</span>{{ curInfo.description }}
          </i-col>
        </Row>
      </div>
    </Modal>
  </div>
</template>
<script>
export default {
  data () {
    return {
      show: false,
      curInfo: {}
    }
  },
  methods: {
    showModal (curInfo) {
      if (!curInfo) {
        this.$Notice.error({ title: '操作提示', desc: '缺少必要参数' })
        return false
      }
      this.curInfo = curInfo
      this.show = true
    }
  }
}
</script>
